import Labels from 'i18n/labels';

export default {
  [Labels.NAME]: 'Admin Tool',
  /* Sidebar */
  [Labels.SIDEBAR_SEARCH_USER]: 'Users',
  [Labels.SIDEBAR_BATCH_USER_DELETE]: 'Batch deletion',
  [Labels.SIDEBAR_TERMS_AND_CONDITIONS]: 'T&C',
  [Labels.SIDEBAR_SEARCH_LOGS]: 'Logs',
  [Labels.SIDEBAR_SEARCH_OTP]: 'OTP',
  [Labels.SIDEBAR_STATUS]: 'Status',
  [Labels.SIDEBAR_BLACKLIST]: 'Blacklist',
  [Labels.SIDEBAR_SELECTOR_SDK]: 'Selector SDK',
  [Labels.SIDEBAR_WHITELIST]: 'Whitelist',
  /* Navbar */
  [Labels.NAVBAR_LOGIN_BUTTON]: 'Login',
  [Labels.NAVBAR_LOGOUT_BUTTON]: 'Logout',
  /* Auth */
  [Labels.AUTH_ERROR_TITLE]: 'Authentication Error',
  [Labels.AUTH_ERROR_BODY]: 'Unexpected authentication status: {status}',
  [Labels.AUTH_LOADING]: 'Authenticating...',
  [Labels.AUTH_REQUIRED_TITLE]: 'Login Required',
  [Labels.AUTH_REQUIRED_BODY]: 'Please authenticate before being able to use the Admin Tool.',
  /* Roles */
  [Labels.ROLE_OPERATION_MANAGER]: 'Operation Manager',
  [Labels.ROLE_FIRST_LINE_SUPPORT_AGENT]: 'First Line Support Agent',
  [Labels.ROLE_SECOND_LINE_SUPPORT_AGENT]: 'Second Line Support Agent',
  [Labels.ROLE_FRAUD_OFFICER]: 'Fraud Officer',
  [Labels.ROLE_MOSUP_UPLOAD]: 'Mosup Upload',
  [Labels.ROLE_MOSUP_PROMOTE]: 'Mosup Promote',
  /* Spinner */
  [Labels.SPINNER_LABEL]: 'Loading...',
  /* BooleanText */
  [Labels.TEXT_YES]: 'Yes',
  [Labels.TEXT_NO]: 'No',
  /* Textarea */
  [Labels.TEXTAREA_COUNT]: '{count}/{max} characters',
  /* Search user */
  [Labels.SEARCH_USER_TITLE]: 'User search',
  [Labels.SEARCH_USER_SUBMIT_BUTTON]: 'Search',
  [Labels.SEARCH_USER_SUBMIT_TOOLTIP]: 'At least one search criteria must be specified',
  [Labels.SEARCH_USER_RESET_BUTTON]: 'Reset',
  [Labels.SEARCH_USER_LABEL_BPCACCOUNTID]: 'BPC account ID',
  [Labels.SEARCH_USER_LABEL_EMAIL]: 'Email',
  [Labels.SEARCH_USER_LABEL_PHONE_NUMBER]: 'Phone number',
  [Labels.SEARCH_USER_LABEL_FIRSTNAME]: 'First name',
  [Labels.SEARCH_USER_LABEL_LASTNAME]: 'Last name',
  [Labels.SEARCH_USER_HAS_MORE_RESULTS]:
    'There are more than {count} results, this page only displays the first {count} results, please refine your search criteria.',
  [Labels.SEARCH_USER_LOADING]: 'Loading...',
  [Labels.SEARCH_USER_EMPTY]: 'No user matching the search criteria.',
  /* User */
  [Labels.USER_TITLE]: 'Account information',
  [Labels.USER_BPC_ACCOUNT_ID]: 'BPC account ID',
  [Labels.USER_SEAID]: 'SeaID (latest)',
  [Labels.USER_PQI_ACCOUNT_ID]: 'PQI account ID',
  [Labels.USER_CW_USER_ID]: 'CW user ID',
  [Labels.USER_EMAIL]: 'Email',
  [Labels.USER_PHONE_NUMBER]: 'Phone number',
  [Labels.USER_FIRSTNAME]: 'First name',
  [Labels.USER_LASTNAME]: 'Last name',
  [Labels.USER_ACCOUNT_STATUS]: 'Account status',
  [Labels.USER_ACCOUNT_STATUS_ACTIVE]: 'Active',
  [Labels.USER_ACCOUNT_STATUS_DELETED]: 'Deleted',
  [Labels.USER_LANGUAGE]: 'User language',
  [Labels.USER_ACCOUNT_CREATION_DATE]: 'Account creation date',
  [Labels.USER_MARKETING_CONSENT]: 'Marketing consent status',
  [Labels.USER_TC_VERSION]: 'Version of the T&C accepted',
  [Labels.USER_VAS_PROVIDER]: 'VAS providers',
  [Labels.USER_LOYALTY_CARDS]: 'Loyalty card(s)',
  [Labels.USER_VOUCHER_ISSUER]: 'Voucher issuer',
  [Labels.USER_REVOKE_ACCOUNT_BUTTON]: 'Revoke account',
  [Labels.USER_DELETE_ACCOUNT_BUTTON]: 'Delete account',
  [Labels.USER_PQI_UNREGISTER_ACCOUNT_BUTTON]: 'PQI Unregister',
  [Labels.USER_LOADING]: 'Loading...',
  [Labels.USER_ERROR_MESSAGE]: 'Error fetching user {id}',
  /* VAS */
  [Labels.VAS_TITLE]: 'VAS consents',
  [Labels.VAS_ID]: 'VAS ID',
  [Labels.VAS_USER_ID]: 'VAS User ID',
  [Labels.VAS_CONSENT]: 'Consent',
  [Labels.VAS_USER_CONSENT_EMPTY]: 'No VAS consents',
  [Labels.VAS_USER_CONSENT_GIVEN]: 'Given',
  [Labels.VAS_USER_CONSENT_NOT_GIVEN]: 'Not Given',
  [Labels.VAS_USER_CONSENT_REFUSED]: 'Refused',
  /* Wallet */
  [Labels.WALLET_STATUS]: 'Status',
  [Labels.WALLET_STATUS_ACTIVE]: 'Active',
  [Labels.WALLET_STATUS_DELETED]: 'Deleted',
  [Labels.WALLET_STATUS_REVOKED]: 'Revoked',
  [Labels.WALLET_STATUS_SUSPENDED]: 'Suspended',
  [Labels.WALLET_CW_STATUS]: 'CW status',
  [Labels.WALLET_CW_STATUS_ACTIVE]: 'Active',
  [Labels.WALLET_CW_STATUS_REVOKED]: 'Revoked',
  [Labels.WALLET_CW_STATUS_SUSPENDED]: 'Suspended',
  [Labels.WALLET_TITLE]: 'Wallet',
  [Labels.WALLET_CARD_ID]: 'Card ID',
  [Labels.WALLET_CARD_ALIAS]: 'Alias',
  [Labels.WALLET_CARD_MASKED_PAN]: 'Masked PAN',
  [Labels.WALLET_CARD_EXPIRY_DATE]: 'Expiry date',
  [Labels.WALLET_CARD_BANK_NAME]: 'Bank',
  [Labels.WALLET_CARD_STATUS]: 'Status',
  [Labels.WALLET_CARD_STATUS_ACTIVE]: 'Active',
  [Labels.WALLET_CARD_STATUS_WAITING]: 'Ongoing 3DS',
  [Labels.WALLET_CARD_EMPTY]: 'No cards',
  /* Delete card */
  [Labels.WALLET_SYNC_NOTIFICATION_SUCCESS]: 'Successfully synchronized wallet',
  [Labels.WALLET_SYNC_NOTIFICATION_ERROR]: 'Failed to synchronize wallet: {error}',
  [Labels.WALLET_SYNC_BUTTON]: 'Synchronize wallet',
  [Labels.WALLET_SYNC_CONFIRMATION_MODAL_TITLE]: 'Are you sure?',
  [Labels.WALLET_SYNC_CONFIRMATION_MODAL_BODY]:
    'You are about to force the synchronization of the wallet between the MAS and CW.',
  [Labels.WALLET_SYNC_CONFIRMATION_MODAL_CONFIRM]: 'Confirm',
  [Labels.WALLET_SYNC_CONFIRMATION_MODAL_CANCEL]: 'Cancel',
  /* Last transaction */
  [Labels.WALLET_DELETE_CARD_NOTIFICATION_SUCCESS]: 'Card deleted',
  [Labels.WALLET_DELETE_CARD_NOTIFICATION_ERROR]: 'Failed to delete card: {error}',
  [Labels.WALLET_DELETE_CARD_BUTTON]: 'Delete',
  [Labels.WALLET_DELETE_CARD_CONFIRMATION_MODAL_TITLE]: 'Are you sure?',
  [Labels.WALLET_DELETE_CARD_CONFIRMATION_MODAL_BODY]:
    'You are about to delete card <code>{id}</code>. This action cannot be undone.',
  [Labels.WALLET_DELETE_CARD_CONFIRMATION_MODAL_CONFIRM]: 'Confirm',
  [Labels.WALLET_DELETE_CARD_CONFIRMATION_MODAL_CANCEL]: 'Cancel',
  /* Last transaction */
  [Labels.LAST_TRANSACTION_TITLE]: 'Last transaction',
  [Labels.LAST_TRANSACTION_ID]: 'ID',
  [Labels.LAST_TRANSACTION_DATE]: 'Date',
  [Labels.LAST_TRANSACTION_TYPE]: 'Type',
  [Labels.LAST_TRANSACTION_STATUS]: 'Status',
  [Labels.LAST_TRANSACTION_STATUS_AUTH_OK]: 'Authorization OK',
  [Labels.LAST_TRANSACTION_STATUS_PRE_AUTH_OK]: 'Pre-authorization OK',
  [Labels.LAST_TRANSACTION_STATUS_AUTH_UNKNOWN]: 'Authorization unknown',
  /* Last session */
  [Labels.LAST_SESSION_TITLE]: 'Last session',
  [Labels.LAST_SESSION_ID]: 'Session ID',
  [Labels.LAST_SESSION_DEVICE_ID]: 'Device ID',
  [Labels.LAST_SESSION_CREATION_DATE]: 'Creation date',
  /* Delete user confirmation modal */
  [Labels.USER_DELETE_CONFIRMATION_MODAL_TITLE]: 'Are you sure?',
  [Labels.USER_DELETE_CONFIRMATION_MODAL_BODY]:
    'You are about to delete user with id <code>{id}</code>. This action cannot be undone.',
  [Labels.USER_DELETE_CONFIRMATION_MODAL_CONFIRM]: 'Delete',
  [Labels.USER_DELETE_CONFIRMATION_MODAL_CANCEL]: 'Cancel',
  [Labels.USER_DELETE_NOTIFICATION_SUCCESS]: 'User successfully deleted',
  [Labels.USER_DELETE_NOTIFICATION_ERROR]: 'Failed to delete user: {error}',
  /* Revoke user confirmation modal */
  [Labels.USER_REVOKE_CONFIRMATION_MODAL_TITLE]: 'Are you sure?',
  [Labels.USER_REVOKE_CONFIRMATION_MODAL_BODY]:
    'You are about to revoke user with id <code>{id}</code>. This action cannot be undone.',
  [Labels.USER_REVOKE_CONFIRMATION_MODAL_CONFIRM]: 'Revoke',
  [Labels.USER_REVOKE_CONFIRMATION_MODAL_CANCEL]: 'Cancel',
  [Labels.USER_REVOKE_NOTIFICATION_SUCCESS]: 'User successfully revoked',
  [Labels.USER_REVOKE_NOTIFICATION_ERROR]: 'Failed to revoke user: {error}',
  /* PQI unregister user confirmation modal */
  [Labels.USER_PQI_UNREGISTER_CONFIRMATION_MODAL_TITLE]: 'Are you sure?',
  [Labels.USER_PQI_UNREGISTER_CONFIRMATION_MODAL_BODY]:
    'You are about to unregister user with phone number <code>{phone}</code> and email <code>{email}</code> from PQI. This action cannot be undone.',
  [Labels.USER_PQI_UNREGISTER_CONFIRMATION_MODAL_CONFIRM]: 'Unregister',
  [Labels.USER_PQI_UNREGISTER_CONFIRMATION_MODAL_CANCEL]: 'Cancel',
  [Labels.USER_PQI_UNREGISTER_NOTIFICATION_SUCCESS]: 'User successfully unregistered from PQI.',
  [Labels.USER_PQI_UNREGISTER_NOTIFICATION_ERROR]: 'Failed to unregister user at PQI: {error}',
  /* Device */
  [Labels.DEVICES_TITLE]: 'Devices',
  [Labels.DEVICE_ID]: 'Device Id',
  [Labels.DEVICE_OS]: 'Device OS',
  [Labels.DEVICE_OS_VERSION]: 'Device OS version',
  [Labels.DEVICE_BRAND]: 'Device brand',
  [Labels.DEVICE_MODEL]: 'Device model',
  [Labels.DEVICE_STATUS]: 'Device status',
  [Labels.DEVICE_STATUS_ACTIVE]: 'Active',
  [Labels.DEVICE_STATUS_DELETED]: 'Deleted',
  [Labels.DEVICE_STATUS_REVOKED]: 'Revoked',
  [Labels.DEVICE_CREATION_DATE]: 'Device creation date',
  [Labels.DEVICE_APP_VERSION]: 'App version installed',
  [Labels.DEVICE_PLATFORM_VERIFIED]: 'Platform verified',
  [Labels.DEVICE_PLATFORM_VERIFICATION_FAILURES]: 'Platform verification failure(s)',
  [Labels.DEVICE_HARDWARE_SECURED]: 'Hardware secure',
  [Labels.DEVICE_DEVICE_KEY_CERTIFICATE]: 'Device key certificate',
  [Labels.DEVICE_PAYMENT_KEY_PIN_CERTIFICATE]: 'Payment key certificate (pin)',
  [Labels.DEVICE_PAYMENT_KEY_BIOMETRICS_CERTIFICATE]: 'Payment key certificate (biometrics)',
  [Labels.DEVICE_EMPTY]: 'No device',
  /* Device delete */
  [Labels.DEVICE_DELETE_BUTTON]: 'Delete device',
  [Labels.DEVICE_DELETE_MODAL_TITLE]: 'Are you sure?',
  [Labels.DEVICE_DELETE_MODAL_BODY]:
    'You are about to delete device with id <code>{id}</code>. This action cannot be undone.',
  [Labels.DEVICE_DELETE_MODAL_CONFIRM]: 'Confirm',
  [Labels.DEVICE_DELETE_MODAL_CANCEL]: 'Cancel',
  [Labels.DEVICE_DELETE_NOTIFICATION_SUCCESS]: 'Device successfully deleted',
  [Labels.DEVICE_DELETE_NOTIFICATION_ERROR]: 'Failed to delete device: {error}',
  /* Device revoke */
  [Labels.DEVICE_REVOKE_BUTTON]: 'Revoke device',
  [Labels.DEVICE_REVOKE_MODAL_TITLE]: 'Are you sure?',
  [Labels.DEVICE_REVOKE_MODAL_BODY]:
    'You are about to revoke device with id <code>{id}</code>. This action cannot be undone.',
  [Labels.DEVICE_REVOKE_MODAL_CONFIRM]: 'Confirm',
  [Labels.DEVICE_REVOKE_MODAL_CANCEL]: 'Cancel',
  [Labels.DEVICE_REVOKE_NOTIFICATION_SUCCESS]: 'Device successfully revoked',
  [Labels.DEVICE_REVOKE_NOTIFICATION_ERROR]: 'Failed to revoke device: {error}',
  /* Batch user revocation */
  [Labels.BATCH_USER_REVOCATION_TITLE]: 'Batch user revocation',
  [Labels.BATCH_USER_REVOCATION_JOB_TITLE]: 'Job {id}',
  [Labels.BATCH_USER_REVOCATION_JOB_SUBTITLE]: 'File {filename} • Uploaded on {date}',
  [Labels.BATCH_USER_REVOCATION_EMPTY_TITLE]: 'No job found',
  [Labels.BATCH_USER_REVOCATION_EMPTY_SUBTITLE]: "There's currently no ongoing or finished batch user revocation job.",
  [Labels.BATCH_USER_REVOCATION_ERROR_TITLE]: 'Error',
  [Labels.BATCH_USER_REVOCATION_TABLE_BPC_ACCOUNT_ID]: 'BPC Account ID',
  [Labels.BATCH_USER_REVOCATION_TABLE_SEA_ID]: 'SeaID',
  [Labels.BATCH_USER_REVOCATION_TABLE_STATUS]: 'Status',
  [Labels.BATCH_USER_REVOCATION_TABLE_DELETION_DATE]: 'Deletion date',
  [Labels.BATCH_USER_REVOCATION_TABLE_ERROR]: 'Error',
  [Labels.BATCH_USER_REVOCATION_TABLE_EXPORT]: 'Export as CSV',
  [Labels.BATCH_USER_REVOCATION_MODAL_TITLE]: 'New batch user revocation',
  [Labels.BATCH_USER_REVOCATION_MODAL_CANCEL]: 'Cancel',
  [Labels.BATCH_USER_REVOCATION_MODAL_UPLOAD]: 'Upload',
  [Labels.BATCH_USER_REVOCATION_MODAL_NEW]: 'New',
  [Labels.BATCH_USER_REVOCATION_UPLOAD_SUCCESS]: 'File successfully uploaded.',
  [Labels.BATCH_USER_REVOCATION_UPLOAD_ERROR]: 'Failed to upload file: {error}',
  [Labels.BATCH_USER_REVOCATION_UPLOAD_INPUT_MESSAGE]: 'Drop a file or click to select a file',
  [Labels.BATCH_USER_REVOCATION_UPLOAD_INPUT_TITLE]:
    'Upload a CSV file containing the list of accounts to be revoked. The CSV file is expected to have two columns: SeaID and MasAccountID.',
  /* Search logs */
  [Labels.SEARCH_LOG_TITLE]: 'Log search',
  [Labels.SEARCH_LOG_SUBMIT_BUTTON]: 'Search',
  [Labels.SEARCH_LOG_RESET_BUTTON]: 'Reset',
  [Labels.SEARCH_LOG_LABEL_LEVEL]: 'Level',
  [Labels.SEARCH_LOG_LABEL_MIN_LEVEL]: 'Min. level',
  [Labels.SEARCH_LOG_LABEL_STARTDATE]: 'Start date',
  [Labels.SEARCH_LOG_LABEL_ENDDATE]: 'End date',
  [Labels.SEARCH_LOG_LABEL_BPCACCOUNTID]: 'BPC Account ID',
  [Labels.SEARCH_LOG_LABEL_MESSAGE]: 'Message',
  [Labels.SEARCH_LOG_LABEL_CONTAINER]: 'Container',
  [Labels.SEARCH_LOG_LABEL_CONTAINER_MAC]: 'MAC',
  [Labels.SEARCH_LOG_LABEL_CONTAINER_KEYCLOAK]: 'Keycloak',
  [Labels.SEARCH_LOG_LABEL_CONTAINER_ALL]: 'All',
  [Labels.SEARCH_LOG_LABEL_LEVEL_TRACE]: 'TRACE',
  [Labels.SEARCH_LOG_LABEL_LEVEL_DEBUG]: 'DEBUG',
  [Labels.SEARCH_LOG_LABEL_LEVEL_INFO]: 'INFO',
  [Labels.SEARCH_LOG_LABEL_LEVEL_WARN]: 'WARN',
  [Labels.SEARCH_LOG_LABEL_LEVEL_ERROR]: 'ERROR',
  [Labels.SEARCH_LOG_LABEL_LINE_NUMBER]: '#',
  [Labels.SEARCH_LOG_LABEL_TIMESTAMP]: 'Timestamp',
  [Labels.SEARCH_LOG_LABEL_DEVICEID]: 'Device ID',
  [Labels.SEARCH_LOG_HAS_MORE_RESULTS]: 'There are more than {count} results. Please refine the search criteria.',
  [Labels.SEARCH_LOG_LOADING]: 'Loading...',
  [Labels.SEARCH_LOG_EMPTY]: 'No matching log.',
  /* Search OTP */
  [Labels.SEARCH_OTP_TITLE]: 'OTP counter',
  [Labels.SEARCH_OTP_LABEL_PREFIX]: 'Type',
  [Labels.SEARCH_OTP_LABEL_SUFFIX]: 'Target',
  [Labels.SEARCH_OTP_LABEL_PHONE_PREFIX]: 'Phone',
  [Labels.SEARCH_OTP_LABEL_EMAIL_PREFIX]: 'Email',
  [Labels.SEARCH_OTP_LABEL_IP_PREFIX]: 'IP',
  [Labels.SEARCH_OTP_LABEL_URL]: 'URL',
  [Labels.SEARCH_OTP_LABEL_REMAINING_CODES]: 'Remaining codes (per 24h)',
  [Labels.SEARCH_OTP_SUBMIT_BUTTON]: 'Search',
  [Labels.SEARCH_OTP_RESET_BUTTON]: 'Reset',
  /* Mas status */
  [Labels.MAS_STATUS_TITLE]: 'Status',
  [Labels.MAS_STATUS_ERROR_MESSAGE]: 'Error retrieving MAS status',
  [Labels.MAS_STATUS_TAB_MAS_CONFIGURATION]: 'MAC configuration',
  [Labels.MAS_STATUS_TAB_IDP_CONFIGURATION]: 'IDP configuration',
  [Labels.MAS_STATUS_TAB_HTTP_CLIENT_STATUS]: 'HTTP client status',
  [Labels.MAS_STATUS_CONFIG_NAME]: 'Name',
  [Labels.MAS_STATUS_CONFIG_KEY]: 'Key',
  [Labels.MAS_STATUS_CONFIG_VALUE]: 'Value',
  [Labels.MAS_STATUS_HTTP_CLIENT_NAME]: 'Name',
  [Labels.MAS_STATUS_HTTP_CLIENT_URL]: 'URL',
  [Labels.MAS_STATUS_HTTP_CLIENT_STATUS]: 'Status',
  [Labels.MAS_STATUS_HTTP_CLIENT_SSL_STATUS]: 'SSL status',
  [Labels.MAS_STATUS_REFRESH_BUTTON]: 'Refresh',
  /* Blacklist */
  [Labels.BLACKLIST_TITLE]: 'Blacklist',
  [Labels.BLACKLIST_RESULT_HEADER]: 'Results linked to: ',
  [Labels.BLACKLIST_ENTRY_TYPE]: 'Entry type',
  [Labels.BLACKLIST_ENTRY_VALUE]: 'Entry value',
  [Labels.BLACKLIST_SUBMIT_BUTTON]: 'Search',
  [Labels.BLACKLIST_DELETE_BUTTON]: 'Delete',
  [Labels.BLACKLIST_OPTION_PHONE]: 'Phone number',
  [Labels.BLACKLIST_OPTION_EMAIL]: 'Email address',
  [Labels.BLACKLIST_OPTION_DEVICE_ID]: 'Device ID',
  [Labels.BLACKLIST_LABEL_TYPE]: 'Type',
  [Labels.BLACKLIST_LABEL_VALUE]: 'Value',
  [Labels.BLACKLIST_LABEL_CREATION_DATE]: 'Date',
  [Labels.BLACKLIST_EMPTY]: 'No result',
  /* Device delete */
  [Labels.BLACKLIST_DELETE_BUTTON]: 'Delete',
  [Labels.BLACKLIST_DELETE_MODAL_TITLE]: 'Are you sure?',
  [Labels.BLACKLIST_DELETE_MODAL_BODY]: 'You are about to remove entry <code>{value}</code> from the blacklist.',
  [Labels.BLACKLIST_DELETE_MODAL_CONFIRM]: 'Confirm',
  [Labels.BLACKLIST_DELETE_MODAL_CANCEL]: 'Cancel',
  [Labels.BLACKLIST_DELETE_NOTIFICATION_SUCCESS]: 'Entry deleted',
  [Labels.BLACKLIST_DELETE_NOTIFICATION_ERROR]: 'Failed to delete blacklist entry: {error}',
  /* MoSup */
  [Labels.MOSUP_CURRENT_VERSION_HEADER]: 'Current version',
  [Labels.MOSUP_STAGING_VERSION_HEADER]: 'Staging version',
  [Labels.MOSUP_VERSION_EMPTY]: 'No version',
  [Labels.MOSUP_VERSION_PLATFORM]: 'Platform',
  [Labels.MOSUP_VERSION_UPLOADED_AT]: 'Uploaded at',
  [Labels.MOSUP_VERSION_UPLOADED_BY]: 'Uploaded by',
  [Labels.MOSUP_VERSION_PROMOTED_AT]: 'Promoted at',
  [Labels.MOSUP_VERSION_PROMOTED_BY]: 'Promoted by',
  [Labels.MOSUP_VERSION_COMMENT]: 'Comment',
  [Labels.MOSUP_DOWNLOAD_TOOLTIP]: 'Download',
  [Labels.MOSUP_VIEW_TOOLTIP]: 'View',
  [Labels.MOSUP_VIEW_MODAL_TITLE]: 'Selector SDK ({platform} - {env})',
  [Labels.MOSUP_VIEW_TAB_RAW]: 'Raw',
  [Labels.MOSUP_VIEW_TAB_DECODED]: 'Decoded',
  [Labels.MOSUP_PROMOTE_BUTTON]: 'Promote',
  [Labels.MOSUP_PROMOTE_MODAL_TITLE]: 'Are you sure?',
  [Labels.MOSUP_PROMOTE_MODAL_BODY]:
    'You are about to promote a new version of the MoSup asset <code>{type}</code> in <code>{environment}</code> environment.',
  [Labels.MOSUP_PROMOTE_MODAL_CONFIRM_BUTTON]: 'Confirm',
  [Labels.MOSUP_PROMOTE_MODAL_CANCEL_BUTTON]: 'Cancel',
  [Labels.MOSUP_PROMOTE_NOTIFICATION_SUCCESS]: 'Version promoted',
  [Labels.MOSUP_PROMOTE_NOTIFICATION_ERROR]: 'Failed to promote version: {error}',
  /* Selector SDK */
  [Labels.SELECTOR_SDK_TITLE]: 'Selector SDK',
  [Labels.SELECTOR_SDK_ANDROID]: 'Android',
  [Labels.SELECTOR_SDK_IOS]: 'iOS',
  [Labels.SELECTOR_SDK_UPLOAD_BUTTON]: 'Upload',
  [Labels.SELECTOR_SDK_UPLOAD_MODAL_TITLE]: 'Upload new version',
  [Labels.SELECTOR_SDK_UPLOAD_MODAL_CONFIRM]: 'Upload',
  [Labels.SELECTOR_SDK_UPLOAD_MODAL_CANCEL]: 'Cancel',
  [Labels.SELECTOR_SDK_UPLOAD_INPUT_TITLE]:
    'The newly uploaded version will replace the staging version for the same platform (if any).',
  [Labels.SELECTOR_SDK_UPLOAD_LABEL_PLATFORM]: 'Platform',
  [Labels.SELECTOR_SDK_UPLOAD_LABEL_COMMENT]: 'Comment',
  [Labels.SELECTOR_SDK_UPLOAD_INPUT_MESSAGE]: 'Drop a file or click to select a file',
  [Labels.SELECTOR_SDK_UPLOAD_SUCCESS]: 'Version uploaded',
  [Labels.SELECTOR_SDK_UPLOAD_ERROR]: 'Failed to upload version: {error}',
  /* Whitelist */
  [Labels.WHITELIST]: 'Whitelist',
  [Labels.WHITELIST_TITLE]: 'Whitelist',

  [Labels.WHITELIST_UPLOAD_BUTTON]: 'Upload',
  [Labels.WHITELIST_UPLOAD_MODAL_TITLE]: 'Upload new version',
  [Labels.WHITELIST_UPLOAD_MODAL_CONFIRM]: 'Upload',
  [Labels.WHITELIST_UPLOAD_MODAL_CANCEL]: 'Cancel',
  [Labels.WHITELIST_UPLOAD_LABEL_COMMENT]: 'Comment',
  [Labels.WHITELIST_UPLOAD_INPUT_TITLE]: 'The newly uploaded version will replace the staging version (if any).',
  [Labels.WHITELIST_UPLOAD_INPUT_MESSAGE]: 'Drop a file or click to select a file',
  [Labels.WHITELIST_UPLOAD_SUCCESS]: 'Version uploaded',
  [Labels.WHITELIST_UPLOAD_ERROR]: 'Failed to upload version: {error}',

  /* Not found */
  [Labels.NOT_FOUND_TITLE]: 'Page Not Found',
  [Labels.NOT_FOUND_SUBTITLE]: "The page you're looking for does not seem to exist",
  [Labels.NOT_FOUND_BUTTON]: 'Go Home',
};
